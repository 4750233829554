import * as types from "../mutations_types";
import auth from "../../services/api/auth";
import jwt from "jsonwebtoken";
import Vue from "../../main";

const state = {
  user: null,
  isLoggedIn: false
};

const getters = {
  isAuth: state => state.isLoggedIn,
  user: state => state.user
};

const actions = {
  async login({ commit }, data) {
    commit(types.SET_LOADING, true);
    try {
      const response = await auth.login(data);
      if (response.body.status == 401) {
        commit(types.LOGIN_FAILED);
        return false
      } else {
        localStorage.setItem("accessToken", response.body.accessToken);
        const info = jwt.decode(response.body.accessToken);
        commit(types.LOGIN_SUCCESS, info);
        return true
      }
      commit(types.SET_LOADING, false);
    }
    finally {
      commit(types.SET_LOADING, false);
    }
  },

  async signOut({ commit }, _context) {
    commit(types.SIGN_OUT);
    localStorage.removeItem("accessToken");
  },

  async refreshAuth({ commit }, _context) {
    const token = localStorage.getItem("accessToken");
    if (token) {
      try {
        const info = jwt.decode(token);
        if (info) {
          commit(types.LOGIN_SUCCESS, info);
          Vue.http.headers.common["Authorization"] = "Bearer " + token;
        } else {
          commit(types.LOGIN_FAILED);
        }
      } catch (e) {
        commit(types.LOGIN_FAILED);
      }
    }
  }
};

const mutations = {
  async [types.LOGIN_SUCCESS](state, userInfo) {
    state.isLoggedIn = true;
    //state.username = auth.getTokenInfo();
    state.user = {
      role: userInfo['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
      email: userInfo.email
    };
  },
  [types.LOGIN_FAILED](state, data) {
    state.isLoggedIn = false;
    state.user = null;
  },
  [types.SIGN_OUT](state, _data) {
    state.isLoggedIn = false;
    state.user = null;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
