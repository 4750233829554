export const SET_LOADING = 'SET_LOADING'
export const SET_PREVIOUS = 'SET_PREVIOUS'

export const CIRCUIT_STARTED = 'CIRCUIT_STARTED'
export const CIRCUIT_FINISHED = 'CIRCUIT_FINISHED'
export const CIRCUIT_TICK = 'CIRCUIT_TICK'
export const CIRCUIT_ERROR_STATION = 'CIRCUIT_ERROR_STATION'
export const CIRCUIT_ERROR_AND_FINISH = 'CIRCUIT_ERROR_AND_FINISH'

export const CIRCUIT_STATUS = 'CIRCUIT_STATUS'

/*
* LOGIN MUTATIONS TYPES
*/

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const REFRESH_AUTH = 'REFRESH_AUTH'
export const SIGN_OUT = 'SIGN_OUT'


/*
 * TEST
 */

export const SELECT_TEST = 'SELECT_TEST'
export const SET_ATHLETES = 'SET_ATHLETES'
export const SET_NEXT_ATHLETE = 'SET_NEXT_ATHLETE'
export const SET_PARAMS = 'SET_PARAMS'

/*
 * SETUPMENU
 */
export const SET_SETUP_SHEET = 'SET_SETUP_SHEET'

/*
 * ATHLETES
 */
export const GET_ATHLETES = 'GET_ATHLETES'


/*
 + GENERAL
 */

export const RECEIVE_ATHLETES = 'RECEIVE_ATHLETES'
export const RECEIVE_ATHLETES_FAILED = 'RECEIVE_ATHLETES_FAILED'

export const RECEIVE_CIVIL_STATUS_SHORT = 'RECEIVE_CIVIL_STATUS_SHORT'
export const RECEIVE_CIVIL_STATUS_SHORT_FAILED = 'RECEIVE_CIVIL_STATUS_SHORT_FAILED'

export const RECEIVE_LEARNING_CHANNEL_SHORT = 'RECEIVE_LEARNING_CHANNEL_SHORT'
export const RECEIVE_LEARNING_CHANNEL_SHORT_FAILED = 'RECEIVE_LEARNING_CHANNEL_SHORT_FAILED'

export const RECEIVE_BELT_SHORT = 'RECEIVE_BELT_SHORT'
export const RECEIVE_BELT_SHORT_FAILED = 'RECEIVE_BELT_SHORT_FAILED'

export const RECEIVE_INSTITUTE_SHORT = 'RECEIVE_INSTITUTE_SHORT'
export const RECEIVE_INSTITUTE_SHORT_FAILED = 'RECEIVE_INSTITUTE_SHORT_FAILED'

export const RECEIVE_RELIGION_SHORT = 'RECEIVE_RELIGION_SHORT'
export const RECEIVE_RELIGION_SHORT_FAILED = 'RECEIVE_RELIGION_SHORT_FAILED'

export const RECEIVE_SCHOLARITY_SHORT = 'RECEIVE_SCHOLARITY_SHORT'
export const RECEIVE_SCHOLARITY_SHORT_FAILED = 'RECEIVE_SCHOLARITY_SHORT_FAILED'

export const RECEIVE_SPORT_SHORT = 'RECEIVE_SPORT_SHORT'
export const RECEIVE_SPORT_SHORT_FAILED = 'RECEIVE_SPORT_SHORT_FAILED'

export const RECEIVE_BLOOD_TYPE_SHORT = 'RECEIVE_BLOOD_TYPE_SHORT'
export const RECEIVE_BLOOD_TYPE_SHORT_FAILED = 'RECEIVE_BLOOD_TYPE_SHORT_FAILED'