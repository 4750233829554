/**
 * Import Dependency
 */
import * as types from './mutations_types'

/**
 * Declare Variable
 */
export const state = {
	loading: false,
	drawer: null,
	routePrevious: null
}
/**
 * Export
 */

export const actions = {
	setLoading({ commit }, status) {
		commit(types.SET_LOADING, status);
	},
	setPrevious({ commit }, previous) {
		commit(types.SET_PREVIOUS, previous);
	},
}

export const mutations = {
	[types.SET_LOADING](state, loading) {
		state.loading = loading;
	},
	[types.SET_PREVIOUS](state, previous) {
		state.routePrevious = previous;
	},
	["SET_DRAWER"](state, payload) {
		state.drawer = payload
	},
}


