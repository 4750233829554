import Vue from "vue";
import { store } from "@/store";
import Router from "vue-router";
import Index from "@/views/Index.vue";

Vue.use(Router);

const withPrefix = (prefix, routes) =>
  routes.map(route => {
    route.path = prefix + route.path;
    return route;
  });

const router = new Router({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: "*", redirect: "/" },
    {
      path: "/",
      name: "Origen",
      component: Index,
      meta: { requiresAuth: false }
    },
    {
      path: "/login",
      name: "Login",
      component: () =>
        import("@/views/auth/Login.vue")
    },
    {
      path: "/home",
      name: "Home",
      meta: { requiresAuth: true },
      component: () =>
        import("@/views/Home.vue")
    },
    ...withPrefix("/admin", [
      {
        path: "/users",
        name: "usersList",
        component: () => import("@/views/admin/UsersList.vue"),
        meta: { requiresAuth: true, role: ["Administrator"] }
      },
      {
        path: "/user/new",
        name: "usernew",
        component: () => import("@/views/admin/User.vue"),
        meta: { requiresAuth: true, role: ["Administrator"] },
        props: { type: "new" }
      },
      {
        path: "/user/:email/edit",
        name: "useredit",
        component: () => import("@/views/admin/User.vue"),
        meta: { requiresAuth: true, role: ["Administrator"] },
        props: { type: "edit" }
      },
      {
        path: "/products",
        name: "productsList",
        component: () => import("@/views/admin/ProductList.vue"),
        meta: { requiresAuth: true, role: ["Administrator"] }
      },
      {
        path: "/product/new",
        name: "product",
        component: () => import("@/views/admin/Product.vue"),
        meta: { requiresAuth: true, role: ["Administrator"] },
        props: { type: "new" }
      },
      {
        path: "/product/:id/edit",
        name: "productEdit",
        component: () => import("@/views/admin/Product.vue"),
        meta: { requiresAuth: true, role: ["Administrator"] },
        props: { type: "edit" }
      },
      {
        path: "/companies",
        name: "companiesList",
        component: () => import("@/views/admin/CompaniesList.vue"),
        meta: { requiresAuth: true, role: ["Administrator"] }
      },
      {
        path: "/company/new",
        name: "companynew",
        component: () => import("@/views/admin/Company.vue"),
        meta: { requiresAuth: true, role: ["Administrator"] },
        props: { type: "new" }
      },
      {
        path: "/company/:idCompany/edit",
        name: "companyedit",
        component: () => import("@/views/admin/Company.vue"),
        meta: { requiresAuth: true, role: ["Administrator"] },
        props: { type: "edit" }
      }
    ]),
    ...withPrefix("/shipping", [
      ...withPrefix("/checkin", [
        {
          path: "/type",
          name: "actionType",
          component: () => import("@/views/shipping/checkin/ActionType.vue"),
          meta: { requiresAuth: true }
        },
        {
          path: "/policies",
          name: "policies",
          component: () => import("@/views/shipping/checkin/Policies.vue"),
          meta: { requiresAuth: true }
        },
        {
          path: "/success",
          name: "success",
          component: () => import("@/views/shipping/checkin/Success.vue"),
          meta: { requiresAuth: true }
        },
        {
          path: "/delivery",
          name: "checkinDelivery",
          component: () => import("@/views/shipping/Inbound.vue"),
          props: { type: "checkin" },
          meta: { requiresAuth: true }
        },
        {
          path: "/pickup",
          name: "checkinPickup",
          component: () => import("@/views/shipping/Outbound.vue"),
          props: { type: "checkin" },
          meta: { requiresAuth: true }
        }
      ]),
      {
        path: "/dashboard",
        name: "dashboard",
        component: () =>
          import(
            "@/views/shipping/Dashboard.vue"
          ),
        meta: { requiresAuth: true }
      },
      {
        path: "/historic",
        name: "historic",
        props: true,
        component: () =>
          import(
            "@/views/shipping/Historic.vue"
          ),
        meta: { requiresAuth: true }
      },
      {
        path: "/inbound/:id",
        name: "inbound",
        component: () => import("@/views/shipping/Inbound.vue"),
        meta: { requiresAuth: true },
        props: { type: "edit" }
      },
      {
        path: "/outbound/:id",
        name: "outbound",
        component: () => import("@/views/shipping/Outbound.vue"),
        meta: { requiresAuth: true },
        props: { type: "edit" }
      },
      {
        path: "/outbound/:order/:id/close/:status",
        name: "closeOutbound",
        component: () => import("@/views/shipping/close/CloseOutbound.vue"),
        meta: { requiresAuth: true },
        props: {}
      },
      {
        path: "/inbound/:order/:id/close/:status",
        name: "closeInbound",
        component: () => import("@/views/shipping/close/CloseInbound.vue"),
        meta: { requiresAuth: true },
        props: {}
      },
      {
        path: "/inbound",
        name: "inboundForShipping",
        component: () =>
          import(
            /* webpackChunkName: "inbound" */ "@/views/shipping/Inbound.vue"
          ),
        meta: { requiresAuth: true },
        props: { type: "new" }
      },
      {
        path: "/outbound",
        name: "outboundForShipping",
        component: () =>
          import(
            /* webpackChunkName: "outbound" */ "@/views/shipping/Outbound.vue"
          ),
        meta: { requiresAuth: true },
        props: { type: "new" }
      },
      {
        path: "/close/report/outbound",
        name: "CloseReportOutbound",
        component: () => import("@/views/shipping/close/ReportOutbound.vue"),
        meta: { requiresAuth: true },
        props: {}
      },
      {
        path: "/close/report/inbound",
        name: "CloseReportInbound",
        component: () => import("@/views/shipping/close/ReportInbound.vue"),
        meta: { requiresAuth: true },
        props: {}
      },
    ])
  ]
});

router.beforeEach(async (to, from, next) => {
  let avoid = false;
  await store.dispatch("refreshAuth");
  const isLogged = store.getters.isAuth;
  store.dispatch("setPrevious", from)
  if (to.name == "login" && isLogged) {
    next("/");
  }
  if (to.matched.some(record => record.meta.role)) {
    const role = store.getters.user.role;
    if (role != 'Administrator') {
      if (!to.meta.role.some(r => role === r)) {
        next(false)
        avoid = true
      }
    }
  }
  if (!avoid) {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (isLogged) {
        next();
      } else {
        next("/login");
      }
    } else {
      next();
    }
  }
});

export default router;
