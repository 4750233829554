import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { store } from "./store/index";
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { apiUrl } from '@/app.config';

import '@fortawesome/fontawesome-free/css/all.css'

Vue.config.productionTip = false

import moment from 'moment'
Vue.prototype.$moment = moment

/*** Vue Mask ***/
import VueMask from 'v-mask'
Vue.use(VueMask);

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

import { VueMaskFilter } from 'v-mask'
Vue.filter('VMask', VueMaskFilter)

/*** Vuelidate ***/
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

/*** VeueResource ***/
import VueResource from "vue-resource";
Vue.use(VueResource);

/*** vuesignature ***/
import VueSignaturePad from "vue-signature-pad";
Vue.use(VueSignaturePad);
require("vue-select-image/dist/vue-select-image.css");

/*** Vue Select Images ***/
import VueSelectImage from "vue-select-image";
Vue.use(VueSelectImage);

/*** Datepicker ***/
import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
Vue.component("VueCtkDateTimePicker", VueCtkDateTimePicker);

import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
Vue.component("DateRangePicker", DateRangePicker);

/*** Toast ***/
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
const options = {
  position: "bottom-center"
};
Vue.use(Toast, options);

import VCurrencyField from 'v-currency-field'


/*** Currency ***/
import { VTextField } from 'vuetify/lib'  //Globally import VTextField
Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
  locale: 'es-MX',
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true
})

Vue.config.productionTip = false;
Vue.http.headers.common["Authorization"] = "Bearer " + localStorage.getItem("accessToken");
Vue.http.headers.common["Access-Control-Allow-Headers"] = "Content-Type";
Vue.http.options.root = apiUrl;
Vue.http.interceptors.push((req, next) => {
  next(res => {
    if (!res.ok) {
      console.log(res)
      if (res.status == 401) {
        localStorage.removeItem("accessToken");
        window.location = "/login";
      }
    }
    return res;
  });
});

export default Vue;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
