import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@/sass/overrides.sass'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify)

const theme = {
      primary: '#1557A3',
      secondary: '#abaf4c',
      accent: '#9C27b0',
      info: '#00CAE3',
}

export default new Vuetify({
      iconfont: 'mdi',
      theme: {
            themes: {
                  dark: theme,
                  light: theme,
            },
      },
});
