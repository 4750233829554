/**
 * Import Dependency
 */
import Vue from 'vue'
import Vuex from 'vuex'

/**
 * Module Scope of VUEX
 */
import auth from './modules/auth'


/**
 * Root Scope of VUEX
 */
import { state, actions, mutations } from './root'
import { getters } from './getters'

/**
 * Config
 */
Vue.use(Vuex)

/**
 * Export
 */
export const store = new Vuex.Store({
  state,
  actions,
  mutations,
  getters,
  modules: {
    auth,
  },
  strict: true
})
