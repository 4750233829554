<template>
  <v-container align-center justify-center>
    <v-layout row wrap justify-center>
      <v-avatar size="130px">
        <img src="@/assets/images/logo.jpg" />
      </v-avatar>
    </v-layout>
    <v-layout row> </v-layout>
  </v-container>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";

export default {
  name: "home",
  computed: {
    ...mapGetters(["user"]),
    showOthers() {
      if (this.user == null) return false;
      else return this.user.role != "Driver";
    },
  },
  mounted() {
    if (this.user) {
      if (this.user.role == "Driver") {
        this.$router.push({ name: "actionType" });
      } else if (this.user.role) {
        this.$router.push({ name: "Home" });
      }
    }
  },
};
</script>
