import Vue from "../../main.js";

const serviceUrl = "auth";
const signinUrl = `${serviceUrl}/login`;
const signoutUrl = `${serviceUrl}/signout`;
const refreshTokenUrl = `${serviceUrl}/token/refresh`;

/**
 * Export
 */
export default {
  login(data) {
    return Vue.http.post(
      signinUrl,
      { username: data.email, password: data.password, grant_type: "password" }
    );
  },
  signout(data) {
    return Vue.http.post(signoutUrl, {
      email: data.email
    });
  },
  refreshToken(data) {
    const accessToken = data;
    return Vue.http.get(`${refreshTokenUrl}`, {
      params: {
        accessToken: accessToken
      }
    });
  }
};
