<template>
<v-app>
    <dashboard-core-app-bar />

    <dashboard-core-drawer />

    <dashboard-core-view />

    <dashboard-core-footer />
</v-app>
</template>

<script>
export default {
    name: 'App',
    components: {
        DashboardCoreAppBar: () => import('@/views/core/AppBar'),
        DashboardCoreDrawer: () => import('@/views/core/Drawer'),
        DashboardCoreSettings: () => import('@/views/core/Settings'),
        DashboardCoreView: () => import('@/views/core/View'),
        DashboardCoreFooter: () => import('./views/core/Footer'),
    },
    data: () => ({
    })
};
</script>
